@charset "UTF-8";
@import "org";

@font-face {
  font-family: 'noto';
  src: url('./font/noto.woff') format('woff');
  font-style: normal;
}


/* Color */
$white: #fff;
$blue: #1276c3;
$grey: #ddd;
$light-grey: #eee;
$black: #222;
$orange: #ffb900;
$red: #d63838;
$line: #52bc25;

/* Modifier */
.shadow_true {
  box-shadow: 1px 1px 0px 1px #222;
  -moz-box-shadow: 1px 1px 0px 1px #222;
  -webkit-box-shadow: 1px 1px 0px 1px #222;
}

.color_blue {
  color: $blue;
}

.is_accent,strong {
  color: $blue;
  font-weight: bold;
  font-size: 16px;
}

/* Block */
h3 {
  font-size: 20px;
  background-image: url(./images/background.png);
  background-position: bottom;
  background-repeat: repeat-x;
  line-height: 2;
  display: inline-block;
  margin: 20px 0;
}

.page-title {
  text-align: center;
  color: $white;
  background-color: $blue;
  line-height: 2;
  margin-top: 0px;
  font-size: 26px;
  box-shadow: 1px 1px 0px 1px #222;
  -moz-box-shadow: 1px 1px 0px 1px #222;
  -webkit-box-shadow: 1px 1px 0px 1px #222;
  margin-bottom: 20px;
}

.page-subtitle {  
  padding: 10px 5px 30px;
  margin-bottom: 30px;
  line-height: 1.5;
  border-bottom: solid 1px $light-grey;
}




$white: #fff;
$blue: #1276c3;
$grey: #ddd;
$light-grey: #eee;
$black: #222;
$orange: #ffb900;
$red: #d63838;
$line: #52bc25;




html body{
  @include sans-serif('noto');
}


.main-header,.footer-content,.footer-main_wrap,.footer__sub__nav,.sub-visual__wrap{
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  display: block;
  padding-left: 0px;
  padding-right: 0px;
  @include cf;
  box-sizing:border-box;
}
.content,.sub-header__content{
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  display: block;
  padding-left: 0px;
  padding-right: 0px;
  box-sizing:border-box;
}
.footer-main{
  width: 100%;
}




.summary{
  padding-top: 12px;
  padding-bottom: 12px;
  margin-bottom: 15px;
  &__thumbnail{
    // width: 50%;
  }
  &__info{
    left: auto;
    // width: 50%;
    // border-bottom: 0px;
  }
  &__time{
    color: $grey;
    font-size: 0.8em;
  }
}

.voice{
  &__left{
    margin-right: 0px;
  }
  &__thumbnail img{
    padding-top: 0px;
  }
  &__title{
    margin-bottom: 30px;
  }
  &__info{
    &:nth-child(2n+1){
  margin-right: 0px;
    }
  }
}
.voice-recent,.jirei-list{
  &__body{
    a{
  margin-right: 0px;
  width: 100%;
  margin-bottom: 12px;
  position: relative;
  &:after{
    content: '';
    display: block;
    background-color: rgba($black, .5);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
  }
    }
  }
  &__header{
    height: auto;
    font-size: 1.3rem;
    padding: 8px 1em;
  }
  .post-sm__title{
    position: relative;
    z-index: 100;
    top: 0px;
  }
}


.jirei{
  &__left{
    margin-right: 0px;
  }
  &__title{
    a{
  color: $white;
    }
  }
}
.jirei-nav{
  &__link{
    width: 100%;
  }
}

.summary{
  &__middle{
    left: auto;
  }
  &__right{
    left: auto;
  }
}

.store{
  &__more{
    width: 130px;
  }
}
.stores-list{
  &__item{
    margin-right: 0px;
    width: 100%;
  }
}
a{
  .store{
    &__address,&__business-time,&__phone{
  color: $black;
    }
  }
  .summary{
    &__content,&__text,&__title{
  color: $black;
    }
  }
}
.shop{
  &__left{
    margin-right: 0px !important;
  }
  &__right{
    left: auto !important;
  }
  &__link{
    width: 100%;
    &.line{
  img{
    height: 20px;
    width: 20px;
    top: 0px;
  }
    }
  }
}

.voices-list{
  &__item{
    width: 100%;
  }
  .summary{
    &__info{
  left: auto !important;
  // width: auto;
  border-bottom: 0px;
    }
    &__thumbnail{
  // width: auto;
    }
  }
}
.voice-nav{
  &__link{
    width: 100%;
  }
}
.flow{
  .hiw-group{
    height: auto;
    border: 1px solid $light-grey;
  }
  .hiw{
    &__icon{
  width: 100px;
  height: 100px;
  margin-top: 0px;
  border: 1px solid $light-grey;     
    }
    &__left{
  margin-bottom: 20px;
    }
    &__body{
  height: auto;
  padding-top: 3em;
  padding-bottom: 3em;
    }
    &__title span{
  font-size: 20px;
    }
    &__arrow{
  left: 50%;
  bottom: -20px;
  margin-left: -50px;
  right: auto;
  top: auto;
  border-width: 20px 50px 0px 50px;
  border-color: $light-grey transparent transparent transparent;
    }
    &:nth-child(2n){
  .hiw__arrow{
    border-color: $white transparent transparent transparent;
  }
    }
  }
}

.symptoms-list{
  .summary__info{
    border-bottom: 0px;
  }
  &__outer{
    margin-bottom: 15px;
  }
  &__item{
    margin-bottom: 0px;
    height: 100%;
  }
}

.footer-header{
  height: auto;
  padding-bottom: 10px;
  &__title{
    line-height: 1.5;
  }
}
.footer-main{
  &__button{
    max-width: 300px;
  }
}
.border_image{
  background:none;
}

.content__body{
  font-size: 1.3rem;
  margin-bottom: 30px;
}

.title{
  &__sub1{
    padding-bottom: 10px;
    font-size: 18px;
    background-image: url(./images/background.png);
    background-position: bottom;
    background-repeat: repeat-x;
    line-height: 1.5;
    display: inline-block;
    margin: 20px 0;
  }
  &__sub2{
    display: block;
    height: auto;
    line-height: 2;
    margin-bottom: 0px !important;
    color: #fff;
    padding: 10px 20px;
    // height: 44px;
    line-height: 1.7;
    box-sizing: border-box;
    background: linear-gradient(225deg, transparent 10px, #222 10px);
    font-size: 1.2rem;
  }
  &__sub3{
    text-align: center;
    color: $white;
    background-color: $blue;
    line-height: 2;
    font-size: 26px;
    box-shadow: 1px 1px 0px 1px $black;
    -moz-box-shadow: 1px 1px 0px 1px $black;
    -webkit-box-shadow: 1px 1px 0px 1px $black;
    margin-bottom: 20px;
    display: block;
    background-image: none;
  }
}
.list{
  &__pt1{
    background-color: $light-grey;
    line-height: 2;
    margin-bottom: 10px;
    padding: 0 10px;
    font-size: 1.5rem;
    i{
  color: $orange;
  margin-right: 1em;
    }
  }
  &__pt2{
    padding-left: 0px;
    list-style-type: none;
    li{
  &:before{
    font-family: FontAwesome;
    content: "\f05d";
    color: $orange;
    margin-right: 0.5em;
  }
    }
  }
}
h2.title__sub1{
  margin-bottom: 15px;
  line-height: 1.3;
}
.symptom{
  &__left{
    margin-right: 0px;
  }
  &__right{
    left: auto;
  }
}
.privacy{
  line-height: 1.7;
  >p{
    margin-bottom: 1em;
  }
  &__list{
    >li{
  margin-bottom: 2em;
  li,p{
    margin-bottom: 1em;
  }
    }
  }
}
.faq{
  &__icon{
    font-size: 1.5em;
  }
  &__answer{
    .faq__icon{
  font-size: 1.5em;
    }
    .inner{
  display: inline;
    }
  }
}
.repair_plans-list{
  .summary{
    &__info{
  border-bottom: 0px;
    }
  }
  a{
    h3 span,.summary__text{
  color: $black;
    }
  }
}

.jirei-list{
  &__body{
    position: relative;
    height: 159px;
  }
  &__ul{
    transition: all .5s ease-in-out;
    width: 1323px;
    height: 133px;
    padding-left: 0px;
    list-style-type: none;
    position: absolute;
    left: 0px;
    top: 0px;
    margin: 0px;
    @include cf;
    li{
  float: left;
  margin-left: 6px;
  margin-right: 6px;
  a{
    width: 133px;
    height: 133px;
  }
    }
  }
  &__wrap{
    position: relative;
  }
  &__pager{
    position: absolute;
    width: 100%;
    z-index: 100;
    top: 50%;
    left: 0px;
    margin-top: -30px;
  }

  &__items{
    margin-left: 5px;
    margin-right: 5px;
  }
  &__categories{
    margin-bottom: 1em;
  }
  &__category_link{
    &:after{
  content: '/';
  margin-right: 0.5em;
  margin-left: 0.5em;
    }
    &:last-child:after{
  display: none;
    }
    a{
  padding: 0.2em 0.5em;
  border: 1px solid $blue;
  color: $blue;
  font-size: 10px;
  &:hover{
    color: $white;
    background-color: $blue;
  }
    }
  }
}
.slick-prev,.slick-next{
  color: $white;
  background-color: $blue;
  height: 60px;
  border: 0px;
  position: absolute;
  z-index: 999;
  margin-top: -30px;
  font-size: 0px;
  top: 50%;
  &:before{
    font-family: FontAwesome;
    color: $white;
    font-size: 12px;
  }
}
.slick-prev{
  left: -7px;
  &:before{ content: "\f104"; }
}
.slick-next{
  right: -7px;
  &:before{ content: "\f105"; }
}

.is_price{
  font-size: 2em;
  color: $red;
}

.is_asterisk{
  color: $red;
  font-size: 1.3em;
}

.text-small{
  font-size: 0.8em;
}

.plan{
  &__example{
    &__image{
  max-width: 100%;
    }
    &__description{
  background-color: $orange;
  padding: 0.5em;
  text-align: center;
    }
  }
  &__contents{
    &__image{
  max-width: 100%;
    }
  }
  &__nav{
    width: 100%;
  }
}
img{
  max-width: 100%;
}
.reasons{
  &__body{
    .box{
  border-bottom: 1px solid $light-grey;
  padding-top: 30px;
  padding-bottom: 50px;
    }
  }
}

.main-header{
  height: auto;
  padding-top: 15px;
  padding-bottom: 15px;
  .site-title{
    font-size: 0.8em;
    color: $black;
    margin: 0px;
    text-align: left;
  }
  &__icons{
    position: relative;
    top: auto;
    display: block;
  }
  &__logo,&__phone{
    display: block;
    top: auto;
    transform: none;
  }
  &__pop{
    position: absolute;
    left: 0px;
    top: 0px;
  }
  &__tel{
    @include cf;
    float: left;
    padding-left: 160px;
    position: relative;
    // background:transparent url('./images/header02.png') left top no-repeat;
    &__phone{
  float: left;
  margin-right: 5px;
  margin-left: 0px;
    }
  }
  &__mail{
    position: relative;
    padding-left: 160px;
    // background:transparent url('./images/header04.png') left top no-repeat;
    float: left;
  }
  &__icon{
    float: none;
    margin-bottom: 1px;
    float: none;
  }
  &__right{
    text-align: right;
  }
  &__right_wrap{
    display: inline-block;
  }
}

@media screen and (max-width: 1000px){
  .main-header{
    &__tel,&__mail{
  padding-left: 0px;
  background:none;
    }
    &__pop{
  display: none;
    }
  }
}
@media screen and (max-width: 768px){
  .main-header{
    &__left,&__right{
  float: none;
  text-align: center;
    }
  }
}

.footer-main{
  padding-left: 12px;
  padding-right: 12px;
  &__nav{
    ul{
  width: auto;
    }
  }
}
.footer{
  clear: both;
  &__sub{
    width: 100%;
    padding: 0px;
    padding-left: 12px;
    padding-right: 12px;
    &__nav{
  position: relative;
    }
    &__pageup{
  right: 0px;
    }
  }
}

.footer-content{
  &__store{
    margin: 0px;
    margin-bottom: 12px;
  }
}
.store{
  padding: 0px;
  box-shadow: none;
}
iframe{
  max-width: 100%;
}

.pager{
  position: relative;
}

.box{
  padding: 12px;
}
.border{
  border: 1px solid $black;
}
.cf{
  @include cf;
}
.flex{
  @include flex;
}
.shadow{
  @include box-shadow($black,1,-1px -1px 0px 1px,1);
}
.shadow-out{
  @include box-shadow($black,1,1px 1px 0px 1px);
}
.mgb30{
  margin-bottom: 30px;
}
.mgb50{
  margin-bottom: 50px;
}
.mgt20{
  margin-top: 20px;
}
.mgb20{
  margin-bottom: 20px;
}
.mgb15{
  margin-bottom: 15px;
}
.pdb30{
  padding-bottom: 30px;
}
.btn{
  border-radius: 0px 0px 0px 0px !important;
  border: 1px solid transparent;
  max-width: 100%;
  background-color: $red;
  color: $white;
  width: 100%;
  &:hover{
    border: 1px solid $red;
    background-color: transparent;
    color: $red !important;
    opacity: 1;
    i:before,img{
  display: none;
    }
  }
  &.green{
    background-color: $line;
    &:hover{
  background-color: transparent;
  border: 1px solid $line;
  color: $line !important;
    }
  }
  &.blue{
    background-color: $blue;
    &:hover{
  background-color: transparent;
  border: 1px solid $blue;
  color: $blue !important;
    }
  }
  .col-md-4 &{
    width: 100%;
    margin-bottom: 10px;
  }
}
.btn-md{
  padding-top: 0px !important;
  padding-bottom: 3px !important;
  width: auto;
}

.content__body{
  h2{
    margin-top: 0px;
  }
  h3{
    margin-bottom: 10px;
    margin-top: 0;
    padding-bottom: 5px;
    margin-top: 10px;
    font-size: 18px;
  }
}
@media screen and (max-width: 768px){
  .content > .content__body{
    width: 100%;
    float: none;
  }
  .content{
    width: 95%;
  }
  .footer-content{
    width: 100%;
  }
  .shop__info__label,.shop__info__content{
    width: 100%;
  }
  .btn-md{
    width: 100%;
  }
}


.estimate{
  h3{
    color: #fff;
    padding: 10px 20px;
    height: auto;
    line-height: 2;
    box-sizing: border-box;
    background-image: none;
    background: linear-gradient(225deg, transparent 10px, #222 10px);
    display: inline-block;
    font-size: 1.2rem;
    margin-bottom: 0px;
    width: 100%;
  }
  table{
    width: 100%;
    border: 1px solid $black;
    border-bottom: 0px;
    margin-bottom: 15px;
    @media screen and (max-width: 600px){
  tbody,tr,td{
    display: block;
    width: 100% !important;
  }
    }
  }
  td{
    padding: 0.5em 1em;
    border-bottom: 1px solid $light-grey;
    &:last-child{
  text-align: right;
    }
  }
  tr:last-child td{
    border-bottom-color: $black;
    background-color: darken($light-grey, 5%);
    font-weight: bold;
    font-size: 1.2em;
  }
}
.tablepress{
  .row-hover{
    padding: 12px;
  }
  .column-2{
    text-align: right;
  }
  tr:last-child td{
    background-color: $light-grey;
    font-weight: bold;
    font-size: 1.5rem;
    border-bottom: 1px solid $black;
  }
}

.maker img{
  &.fl{
    float: left;
    margin-right: 1em;
  }
  &.fr{
    float: right;
    margin-left: 1em;
  }
}

@media screen and (max-width: 400px){
  .maker img{
    width: 100%;
    height: auto;
    &.fl,&.fr{
  float: none;
  margin-right: 0em;
  margin-left: 0em;
    }
  }
}


.wpcf7{
  .form-group{
    padding: 1em;
    border-bottom: 1px solid $light-grey;
    p{
  display: none;
    }
    input,textarea,select{
  max-width: 100%;
  font-size: 16px;
    }
  }
  label{
    font-size: 1.1em;
    font-weight: bold;
    span{
  font-weight: normal;
    }
  }
  .screen-reader-response{
    padding: 12px;

    text-align: center;
  }
  .wpcf7-response-output{
    display: none;
  }
  .wpcf7-submit{
    padding: 0.5em 1em;
    background-color: $red;
    border: 1px solid transparent;
    color: $white;
    font-size: 1.5em;
    &:hover{
  color: $red;
  background-color: transparent;
  border: 1px solid $red;
    }
  }
}

.sub-visual{
  background-color: #F5F5F5;
  h2{
    background-color: $white;
    margin: 0px;
  }
  &__wrap{
    >div,h2{
  padding-top: 15px;
  padding-bottom: 15px;
    }
  }
  &__middle{
    position: relative;
    padding-left: 0px !important;
    padding-right: 0px !important;
    img{
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  max-width: 100%;
  box-sizing:border-box;
  padding-left: 2%;
  padding-right: 2%;
    }
  }
}
.post-sm{
  min-height: 133px;
  min-width: 133px;
}
#home{
  .post-sm{
    margin: 0px;
  }
  a.post-sm{
    width: 100%;
  }
  .archive{
    &__body{
  height: auto;
  a.post-sm{
    position: relative;
    &:after{
      content: '';
      display: block;
      background-color: rgba($black, .5);
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0px;
      left: 0px;
    }
    .post-sm__title{
      position: relative;
      z-index: 100;
      top: 0px;
    }
  }
    }
  }
  .jirei-list__ul li a{
    width: 133px;
    height: 133px;
  }
  .hiw-group{
    height: auto;
    border: 1px solid $light-grey;
  }
  .section__header{
    font-size: 24px;
    font-weight: normal;
  }
  .hiw__body{
    height: auto;
    .is_accent{
  font-size: 13px;
    }
  }
  .label-lg{
    line-height: 1.7;
    height: auto;
  }
  .repair-case{
    width: 100%;
    position: relative;
    height: 100%;
    &__title{
  height: auto;
  line-height: 1.7;
  font-weight: bold;
  text-align: left;
  font-size: 16px;
  padding: 0px;
    }
    &__summary{
  height: auto;
    }
    .box{
  padding: 5px 5px 1.5em;
    }
    &__summary{
  ul{
    padding-left: 0px;
    list-style-type: none;
    li{
      font-size: 12px;
      &:before{
    font-family: FontAwesome;
    content: "\f05d";
    color: $orange;
    margin-right: 0.5em;
      }
    }
  }
    }
    .btn{
  width: 100%;
  padding-bottom: 3px;
  line-height: 1.2;
  position: absolute;
  bottom: 0px;
  left: 0px;
    }
  }
  .home__section01{
    padding-bottom: 30px;
    .section__content{
  padding-bottom: 2px;
    }
  }
}

#main{
  .repair-case{
    &__summary{
  height: auto;
  ul{
    padding-left: 0px;
    list-style-type: none;
    li{
      font-size: 12px;
      &:before{
    font-family: FontAwesome;
    content: "\f05d";
    color: $orange;
    margin-right: 0.5em;
      }
    }
  }
    }
  }
}

@media screen and (min-width: 769px){
  #home{
    .hiw__description{
  margin-left: 15px;
    }
    .hiw__arrow{
  top: 50%;
  margin-top: -200px;
    }
    .hiw__title{
  span{
    font-size: 16px;
    font-weight: bold;
  }
    }
  }
}
@media screen and (max-width: 1000px){
  #home{
    .hiw{
  &__icon{
    width: 100px;
    height: 100px;
    margin-top: 0px;
    border: 1px solid $light-grey;     
  }
  &__left{
    margin-bottom: 20px;
  }
  &__body{
    height: auto;
    padding-top: 3em;
    padding-bottom: 3em;
  }
  &__title span{
    font-size: 20px;
  }
  &__arrow{
    left: 50%;
    bottom: -20px;
    margin-left: -50px;
    right: auto;
    top: auto;
    border-width: 20px 50px 0px 50px;
    border-color: $light-grey transparent transparent transparent;
  }
  &:nth-child(2n){
    .hiw__arrow{
      border-color: $white transparent transparent transparent;
    }
  }
    }
  }
}
@media screen and (max-width: 480px){
  .voice-recent__nav{
    max-width: 120px;
  }
}
a{
  transition: all .5s ease-in-out;
}
.sidebar-list{
  &__item{
    line-height: 1.7;
    padding: 0px;
    i{
  color: $orange;
    }
    a{
  color: $black;
  display: block;
  padding: 7px 20px;
  &:hover{
    text-decoration: none;
    background-color: rgba($black, .1);
  }
    }
  }
}
.sub-header{
  height: auto;
}
.sub-header__content{
  .navbar{
    margin-bottom: 0px;
  }
  .navbar-default{
    background-color: transparent;
    border: 0px;
    li{
  a{
    i{
      color: $orange;
      margin-right: 0.5em;
    }
  }
    }
  }
  .navbar-default .navbar-collapse, .navbar-default .navbar-form{
    border: 0px;
  }
  .navbar-default .navbar-nav > li > a, .navbar-default .navbar-text{
    color: $white;
    &:hover{
  color: $orange;
    }
  }
  .navbar-default .navbar-nav > li > a{
    padding-left: 2em;
    padding-right: 2em;
  }
  .navbar-default .navbar-toggle .icon-bar{
    background-color: $white;
  }
  .navbar-default .navbar-toggle{
    border: 1px solid $white;
  }
}
.maker__body{
  h3{
    padding-bottom: 10px !important;
  }
}
.sidebar-list{
  &__item{
    height: auto !important;
  }
}
.sidebar-banner{
  max-width: 280px;
  margin-left: auto;
  margin-right: auto;
  p{
    text-align: left;
    color: $black;
    font-size: 0.8em;
  }
}


p{
  line-height: 1.7;
}

.footer-main{
  &.in_text{
    background-color: #fafafa;
    min-height: 1em;
  }
}
.slick-slide{
  height: auto !important;
}

.breadcrumbs{
  background-color: $light-grey;
  text-align: left;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  font-size: 10px;
}

.nav{
  &__child{
    list-style-type: none;
    padding: 0.5em 1em;
    box-sizing:border-box;
    display: none;
    li{
  padding: 0.5em 1em;
  font-size: 0.9em;
  i{
    color: $orange;
    font-size: 0.8em;
    margin-right: 0.5em;
  }
    }
  }
}

.store__name.title__sub1{
  margin-bottom: 10px;
  font-size: 24px;
  line-height: 1.5;
}

.repair-case__title{
  background:none;
}
.hiw__title{
  background:none;
}
.footer-header__title{
  margin-top: 0px;
}
.footer-content{
  .store__name{
    margin-top: 0px;
  }
}

.plan__example__description{
  margin-bottom: 15px !important;
  font-size: 14px;
  background-image: none;
  display: block;
}

.footer__copy{
  text-align: center;
  background-color: $blue;
  color: $white;
}

#voices{
  .voices-list{
    &__outer{
  margin-bottom: 15px;
    }
    &__item{
  margin-bottom: 0px;
  height: 100%;
    }
  }
}

@media screen and (min-width: 769px){
  .nav{
    &__has_child{
  position: relative;
  &:hover{
    .nav__child{
      display: inline-block;
    }
  }
    }
    &__child{
  display: none;
  position: absolute;
  top: 50px;
  left: 0px;
  z-index: 10;
  // padding-left: 0px;
  background-color: $white;
  min-width: 15em;
  li{
    border-bottom: 1px solid $blue;
    &:last-child{
      border-bottom: 0px;
    }
  }
    }
  }
  .navbar-header{
    display: none;
  }
}
.navbar-header button.btn{
  display: none;
}
.main-header{
  &__left,&__right{
    height: auto;
  }
}
@media screen and (max-width: 768px){
  .nav{
    &__child{
  li{
    padding-left: 3.5em;
    a{
      color: $white;
    }
  }
    }
  }
  .navbar-header{
    button.btn{
  display: inline-block;
  width: 70px;
  padding: 6px 10px;
  margin-top: 8px;
  margin-left: 8px;
  border-radius: 3px 3px 3px 3px !important;
  border: 1px solid $white;
    }
  }
  .mobile-nav{
    &.fixed{
  position: fixed;
  bottom: 0px;
  width: 100%;
  z-index: 999;
    }
  }
  .navbar-header{
    position: relative;
    .footer_spnav-group{
  position: absolute;
  left: 0px;
  bottom: 0px;
  height: 100%;
  img{
    float: left;
    max-height: 100%;
  }
    }
  }
  .footer-main{
    &__info{
  text-align: center;
    }
    &__button{
  margin-left: auto;
  margin-right: auto;
    }
    &__image{
  margin-left: auto;
  margin-right: auto;
    }
  }
}


.repairflow{
  @include flex;
  padding-top: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid $grey;
  &:last-child{
    border-bottom: 0px;
  }
  > div{
    position: relative;
  }
  &__left{
    text-align: center;
    padding: 15px;
    position: absolute;
    width: 100%;
    top: 50%;
    left: 0px;
    transform: translateY(-50%);
    @media screen and (max-width: 768px){
  top: auto;
  left: auto;
  position: relative;
  transform: translateY(0%);
    }
  }
  &__num{
    display: block;
    color: $orange;
  }
  &__title{
    margin-bottom: 0px !important;
  }
  &__image{
    text-align: center;
  }
  &__description{
    padding: 15px;
  }
}

label{
  @include cf;
}
.hissu{
  color: $white;
  background-color: $red;
  display: inline-block;
  line-height: 2;
  @include font-size(14);
  padding-left: 0.5em;
  padding-right: 0.5em;
  margin-left: 0.5em;
  margin-right: 0.5em;
  border-radius: 3px 3px 3px 3px;
}


.footer-content{
  &__store{
    &_inner{
  height: 100%;
    }
    &_link{
  height: 100%;
  display: block;
    }
  }
}

.stores-list{
  &__inner{
    padding-bottom: 15px;
  }
  &__item{
    height: 100%;
  }
}

.repairflow{
  &__item{
    padding-top: 5px;
    padding-bottom: 5px;
    @include cf;
    .hiw__title{
  margin-bottom: 0px;
    }
  }
  &__image,&__description{
    text-align: center;
    padding: 15px;
    position: absolute;
    width: 100%;
    top: 50%;
    left: 0px;
    transform: translateY(-50%);
    @media screen and (max-width: 750px){
  top: auto;
  left: auto;
  position: relative;
  transform: translateY(0%);
    }
  }
  &__description{
    text-align: left;
    p{
  margin-bottom: 0px;
    }
  }
  @media screen and (max-width: 750px){
    .repairflow__item{
  height: auto !important;
    }
  }
}

.maker{
  &__more{
    display: block;
    // margin-bottom: 10px;
    text-align: center;
    position: relative;
    height: 100%;
    padding: 10px;
    img{
  
    }
  }
  &__logo{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0px;
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
  }
}
.makers-list__item{
  border: 1px solid $light-grey;
}
.makers{
  &__title_mini{
    position: absolute;
    bottom: -1.5em;
    left: 0px;
    @include font-size(16);
    width: 100%;
  }
}

.makers-list{
  &__item{
    padding-bottom: 10px;
  }
}

.footer{
  &__maker{
    @include cf;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;
    &_item{
  border: 0px;
  padding-bottom: 0px;
    }
    &_more{

    }
    &_logo{

    }
  }
}

.maker__price{
  h3{
    color: #fff;
    padding: 10px 20px;
    height: auto;
    line-height: 2;
    box-sizing: border-box;
    background-image: none;
    background: linear-gradient(225deg, transparent 10px, #222 10px);
    display: inline-block;
    font-size: 1.2rem;
    margin-bottom: 0px;
    width: 100%;
    @media screen and (max-width: 600px){
  margin-top: 15px;
  cursor: pointer;
  position: relative;
  &:after{
    font-family: fontawesome;
    content: "\f107";
    color: $white;
    font-size: 2em;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
    }
  }
  table{
    width: 100%;
    border: 1px solid $black;
    margin-bottom: 15px;
    @media screen and (max-width: 600px){
  tbody,tr,td{
    display: block;
    width: 100% !important;
  }
    }
    @media screen and (min-width: 600px){
  tr{
    &:nth-child(even){
      background-color: $light-grey;
      font-weight: normal;
      font-size: 1em;
    }
    &:nth-child(odd){
      background-color: $white;
      font-weight: normal;
      font-size: 1em;
    }
    &:last-child{
      td{
    border-bottom: 0px;
      }
    }
  }
    }
    td{
  padding: 0.5em 1em;
  border-bottom: 1px solid $light-grey;
    }
  }
}

.text-center{
  text-align: center;
}
.text-right{
  text-align: right;
}

#googleMap{
  height: 300px;
  width: 100%;
}

.newsList-list{
  &__item{
    @include cf;
  }
  &__outer{
    &:nth-child(even){
  background: lighten($light-grey, 3%);
    }
  }
  &__info{
    border-bottom: 0px;
  }
  &__type_archive{
    height: auto;
    margin-bottom: 0px;
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
  }
}


.shop__bottom{
  .shop__link{
    margin-bottom: 10px;
  }
}

#newsList .summary{
  &__text2 {
    line-height: 1.5;
    margin-bottom: 10px;
  }
}
.editor__content{
  h2{
    padding: 0.3em 0.5em;
    border: 1px solid $blue;
    margin-bottom: 0.5em;
    color: $blue;
    font-size: 22px;
  }
  h3{
    margin-bottom: 0.5em;
    margin-top: 0;
    padding-bottom: 10px;
    font-size: 20px;
  }
  h4{
    margin-top: 0px;
    margin-bottom: 0.5em;
    &:before{
  font-family: fontawesome;
  content: "\f138";
  color: $orange;
  margin-right: 0.5em;
    }
  }
}

.symptoms-list{
  &__item{
    width: 100%;
  }
}

.fts20{
  font-size: 20px;
}

.summary_date{
  color: #aaa;
  margin-bottom: 1em;
  font-size: 12px;
}

.summary__title span{
  font-size: 18px;
}


.voices-list{
  &__outer{
    margin-bottom: 15px;
  }
  &__item{
    height: 100%;
    margin-bottom: 0px;
  }
}

.search{
  &__wrap{
    padding: 0px 15px;
    border: 1px solid $light-grey;
    margin-bottom: 30px;
    .open__btn{
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
  display: block;
  border-bottom: 1px solid $light-grey;
  font-size: 18px;
  font-weight: bold;
  color: $blue;
    }
  }
  &__jirei{
    width: 100%;
    display: inline-block;
  }
}
.jireis__subtitle{
  border-bottom: 0px;
  margin-bottom: 0px;
}


.head-content{
  line-height: 1.7;
  height: auto;
  text-align: center;
  color: $white;
  background-color: $blue;
  margin-top: 0px;
  padding: 0.5em 1em 0.1em 1em;
  box-shadow: 1px 1px 0px 1px #222;
  margin-bottom: 20px;
  @include cf;
  &__title{
    font-size: 26px;
  }
  &__description{
    font-size: 12px;
  }
}

.footer-header{
  &__subtitle{
    font-size: 12px;
  }
}

.strongs{
  margin-bottom: 15px;
  border: 1px solid $light-grey;
  padding-left: 0px !important;
  padding-right: 0px !important;
  @include flex;
  &__title{
    background: none;
    border-bottom: 2px solid $light-grey;
    display: block;
    color: $blue;
    padding: 10px !important;
    margin: 0px !important;
  }
  &__contents{
    padding: 10px;
  }
  &__description{
    padding: 10px;
  }
  &__box{
    text-align: center;
    position: relative;
    background-color: $blue;
    &_inner{
  margin-bottom: 0px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  @media screen and (max-width: 768px){
    left: auto;
    right: 0%;
    transform: translateX(-25%) translateY(-50%);
  }
    }
  }
  &__string{
    margin-bottom: 0px;
    color: $white;
    @media screen and (max-width: 768px){
  display: none;
    }
  }
  &__number{
    color: $orange;
    font-size: 48px;
    font-style: italic;
    margin-bottom: 0px;
    line-height: 1.2;
    text-indent: -0.2em;
  }
  &:last-child{
    margin-bottom: 60px;
  }
}



.pickup-box{
  @include cf;
  margin-bottom: 30px;
  border: 5px solid $blue;
  &__title{
    color: $white;
    background-color: $blue;
    text-align: center;
    padding: 0.2em 0.5em 0.4em;
    line-height: 1;
    margin-bottom: 0px;
  }
  &__inner{
    @include cf;
    padding: 10px 5px;
  }
  &__imagewrap{

  }
  &__image{

  }
  &__contents{
    text-align: center;
  }
  &__description{

  }
  &__detail{
    display: block;
    font-size: 26px !important;
    padding-bottom: 15px !important;
  }
  &__price{
    display: block;
    font-size: 48px;
    color: $red;
    font-weight: bold;
    line-height: 1;
    &_after{
  font-weight: normal;
  font-size: 24px;
    }
  }
  &__btn{
    &_wrap{
  margin-top: 10px;
    }
  }
}


.shadow-box{
  margin-bottom: 15px;
  &__inner{
    @include cf;
    box-shadow: $black -1px -1px 0 1px inset;
    padding: 0px 12px 12px;
    box-sizing: border-box;
    height: 100%;
  }
}
